body { background-color: #ffffff; }
body { color: var(--clr-275); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 30px;

@media #{$large-up} {
font-size: 40px;

}
}
h2 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 30px;

}
}
h3 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 25px;

}
}
h4 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 22px;

}
}
h5 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
h6 {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 22px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-275);}
a:hover {color: var(--clr-276);}
/* Main Menu:2 */
nav.me-Menu.MES2 {
& .menu-item.MEC2, & .menu-item.MEC2 > div.MEC2{ & > a.MEC2{color: var(--clr-277);
font-size: 16px;
@media #{$large-up} {
font-size: 24px;
}text-transform: uppercase;
}
 &:hover > a.MEC2{color: var(--clr-276);
}
 }
&.horizontal > .menu-item.MEC2 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC2 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC2 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC2 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC2.active { & > a{ color: var(--clr-276);}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Home Carousel:3 */
.MES3 {
& .slider-arrow {color: var(--clr-278);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{background-color: 5;
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: 1;
border-radius: 50%;
border-style:solid;
border-width:20px;
@media #{$medium-up} {
border-width: 20px;
};
@media #{$large-up} {
border-width: 20px;};
&:hover{border-color: 2;}
background-color: 3;
background-clip: padding-box;
&:hover {background-color: 4;}
width:5px;
height:5px;
@media #{$medium-up} {
width:5px;
height:5px;
};
@media #{$large-up} {
width:5px;
height:5px;
};
&:hover{background-color: 4;}
}}
 }
/* Copyright Panel:4 */
.MES4 {
font-size: 12.8px;
 }
.MES4 {
font-size: 12.8px;
 }
cite.MEC4{
font-size: 12.8px;
}
/* Responsive Menu:5 */
nav.responsive-menu {
.menu-item.MEC5{background-color: var(--clr-279);}
& .menu-item.MEC5, & .menu-item.MEC5 > div.menu-item-wrap{ & > a.MEC5, & > i{color: var(--clr-275);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC5 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 15px;}

& .sub-menu{ .sub-menu {}}

 }
/* Horizontal Main Menu:6 */
nav.me-Menu.MES6 {
& .menu-item.MEC6, & .menu-item.MEC6 > div.MEC6{ & > a.MEC6{color: var(--clr-277);
text-transform: uppercase;
}
 &:hover > a.MEC6{color: var(--clr-276);
}
 }
&.horizontal > .menu-item.MEC6 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC6 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC6 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC6 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 30px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Body Style:7 */
.MES7 {
background-color: var(--clr-275);
&:hover, &.hover { background-color: var(--clr-275);}
color: #ffffff;
 }
.MES7 {
background-color: var(--clr-275);
&:hover, &.hover { background-color: var(--clr-275);}
color: #ffffff;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: #ffffff;
 }
 }
cite.MEC7{
color: #ffffff;
}
/* Banner Panel:8 */
.MES8 {
background-color: var(--clr-280);
color: #ffffff;
padding: 15px;

 }
.MES8 {
background-color: var(--clr-280);
color: #ffffff;
padding: 15px;

h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
cite.MEC8{
color: #ffffff;
}
/* About List :9 */
.MES9 {
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
.MES9 {
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
cite.MEC9{
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Horizontal Main Menu2:10 */
nav.me-Menu.MES10 {
& .menu-item.MEC10, & .menu-item.MEC10 > div.MEC10{ & > a.MEC10{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC10{color: var(--clr-276);
}
 }
&.horizontal > .menu-item.MEC10 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC10 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC10 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC10 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 30px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Main Menu white:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 24px;
}text-transform: uppercase;
}
 &:hover > a.MEC11{color: var(--clr-276);
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC11.active { & > a{ color: var(--clr-276);}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Primary:12 */
.MES12 {
background-color: var(--clr-275);
color: #ffffff;
 }
/* Primary:13 */
.MES13 {
background-color: var(--clr-275);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-275);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Header Scroll Panel:14 */
.MES14 {
background-color: var(--clr-278);
 }
.MES14 {
background-color: var(--clr-278);
 }
/* News List:15 */
.MES15 {
background-color: var(--clr-281);
color: var(--clr-275);
 }
.MES15 {
background-color: var(--clr-281);
color: var(--clr-275);
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-275);
 }
 }
cite.MEC15{
color: var(--clr-275);
}
/* Form Submit Button:16 */
.MES16 {
background-color: var(--clr-281);
&:hover {background-color: var(--clr-282);}
color: var(--clr-275);
&:hover { color: var(--clr-275);}
border-radius: 20px;
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: transparent; }
 }
/* Share Buttons:18 */
.me-block.me-ShareButton .MES18{
figure.MEC18 { background-color: #ffffff;
&:hover{background-color: #ffffff;}
 }
figure.MEC18 { border-color: var(--clr-275);
border-style: solid;
&:hover{border-color: var(--clr-275);}
border-width: 1px;
 }
figure.MEC18{ a { font-size: 100px;

color: var(--clr-275);
 }
&:hover a {color: var(--clr-275);}
 }

 }
/* News Grid:19 */
.MES19 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-279);}
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-275);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES19 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-279);}
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-275);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Buy Button:20 */
.MES20 {
background-color: var(--clr-283);
&:hover {background-color: var(--clr-284);}
color: #ffffff;
padding: 15px 30px;

 }
/* Secondary:21 */
.MES21 {
background-color: var(--clr-285);
color: var(--clr-275);
 }
/* Secondary:22 */
.MES22 {
background-color: var(--clr-285);
color: var(--clr-275);
 }
.MES22 {
background-color: var(--clr-285);
color: var(--clr-275);
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: var(--clr-275);
 }
 }
cite.MEC22{
color: var(--clr-275);
}
/* Alternate:23 */
.MES23 {
background-color: var(--clr-279);
color: var(--clr-275);
 }
/* Alternate:24 */
.MES24 {
background-color: var(--clr-279);
color: var(--clr-275);
 }
.MES24 {
background-color: var(--clr-279);
color: var(--clr-275);
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: var(--clr-275);
 }
 }
cite.MEC24{
color: var(--clr-275);
}
/* Biege line:25 */
.MES25 {
padding: 15px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-279);}
& > hr {border-top-style: solid;}
 }
